import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  score: {
    e: 0,
    i: 0,
    s: 0,
    n: 0,
    f: 0,
    t: 0,
    j: 0,
    p: 0,
  },
  quizTransition: false,
  quizIndex: 0,
  quiz: [
    {
      // snQ1
      q: "연인과 놀이동산에 도착! 놀이 기구를 정할 때의 당신은?",
      a: [
        {
          target: "s",
          score: 1,
          text: "내겐 다 계획이 있지! 지도를 보며 베스트 3위를 정한다.",
        },
        {
          target: "n",
          score: 1,
          text: "무조건 가까운 곳! 사람이 없어 보이는 것부터 탄다.",
        },
      ],
    },
    {
      // eiQ1
      q: "소개팅으로 이성과 첫 만남을 한 당신은?",
      a: [
        {
          target: "e",
          score: 1,
          text: "먼저 질문을 던지며 대화를 시작한다.",
        },
        {
          target: "i",
          score: 1,
          text: "밝은 표정으로 상대가 먼저 말하길 기다린다.",
        },
      ],
    },
    {
      // snQ2
      q: "썸 타는 이성에게서 기다리던 카톡이 온 걸 본 당신은?",
      a: [
        {
          target: "s",
          score: 1,
          text: "우선 하고 있던 일부터! 내 일을 마무리 하고 보낸다. ",
        },
        {
          target: "n",
          score: 1,
          text: "어머 이건 답장해야 해! 묻고 따지지도 않고 칼 답한다.",
        },
      ],
    },
    {
      // eiQ2
      q: "연인과 만나는 날, 당신이 선호하는 데이트는?",
      a: [
        {
          target: "e",
          score: 1,
          text: "가장 핫한 맛집에서 사진도 찍는 인싸 데이트",
        },
        {
          target: "i",
          score: 1,
          text: "집에서 편한 차림으로 영화 보며 꽁냥거리는 데이트",
        },
      ],
    },
    {
      // jpQ1
      q: "연인과 함께 TV를 보게 되었다. 리모컨을 쥐고 있는 당신은?",
      a: [
        {
          target: "j",
          score: 1,
          text: "이것저것 채널을 돌리며 재밌는 채널을 찾는다.",
        },
        {
          target: "p",
          score: 1,
          text: "일정 시간 동안 하나의 채널을 시청한다.",
        },
      ],
    },
    {
      // snQ3
      q: "썸 타는 이성과의 만남 전 선물할 꽃을 고르러 간 당신은?",
      a: [
        {
          target: "n",
          score: 1,
          text: "꽃을 받고 좋아하는 이성의 표정을 상상한다.",
        },
        {
          target: "s",
          score: 1,
          text: "꽃의 향기를 맡아보고 색과 모양, 질감 등을 본다.",
        },
      ],
    },
    {
      // ftQ1
      q: "곧 있으면 연인과의 기념일을 맞아 선물을 고르게 된 당신은? ",
      a: [
        {
          target: "f",
          score: 1,
          text: "연인에게 요즘 가장 필요할 것 같은 선물을 고른다.",
        },
        {
          target: "t",
          score: 1,
          text: "실용성보다는 예쁘고 보기 좋은 선물을 고른다.",
        },
      ],
    },
    {
      // ftQ2
      q: "길을 가다가 썸 타는 이성이 지나가던 사람과 부딪혔다. 당신은?",
      a: [
        {
          target: "t",
          score: 1,
          text: "뭐야? 사과하라고 이야기한다.",
        },
        {
          target: "f",
          score: 1,
          text: "괜찮아? 다친데 없어?라고 물어본다.",
        },
      ],
    },
    {
      // jpQ2
      q: "연인과 말다툼을 하게 된 당신은?",
      a: [
        {
          target: "p",
          score: 1,
          text: "잘잘못보다는 연인의 기분이 우선이다.",
        },
        {
          target: "j",
          score: 1,
          text: "기분이 조금 상하더라도 잘잘못이 더 우선이다.",
        },
      ],
    },
    {
      // ftQ3
      q: "직장 상사와 한 판하고 온 후 연인과 통화하게 된 당신은?",
      a: [
        {
          target: "t",
          score: 1,
          text: "하루 동안 무슨 일이 있었는지 처음부터 끝까지 다 이야기한다",
        },
        {
          target: "f",
          score: 1,
          text: "기분 풀리는 게 먼저! 상사 욕부터 하고 나머지 일은 생략 가능하다.",
        },
      ],
    },
    {
      // eiQ3
      q: "쉬는 날! 하루 종일 집에 있게 된 당신은?",
      a: [
        {
          target: "e",
          score: 1,
          text: "답답해서 버틸 수 없다. 어디라도 나가야 한다!",
        },
        {
          target: "i",
          score: 1,
          text: "아주 좋아! 집 밖은 위험해~",
        },
      ],
    },
    {
      // jpQ3
      q: "기발한 아이디어가 떠오른 당신은? ",
      a: [
        {
          target: "j",
          score: 1,
          text: "완벽한 사전조사를 진행한 뒤 실행한다.",
        },
        {
          target: "p",
          score: 1,
          text: "곧장 실행에 옮기며 수정과 보완을 한다.",
        },
      ],
    },
  ],
  result: {
    isfp: {
      title: "평화로운 낙천적 고양이",
      text:
        "사람들에게 온화하며 다정하고 친절하지만 상대방을 잘 알게 될 때까지 속 마음을 잘 보이지 않는 스타일이에요. 그래서 연인이 당신의 마음을 궁금해할 수 있어요. 하지만 눈치가 빠르기 때문에 상황 파악을 잘해 큰 의견 충돌을 피하려고 노력할 거예요! 나는 결정력과 추진력을 기르고, 이해심이 많고 애정표현을 잘하는 상대방을 만나면 행복한 연애를 할 수 있어요!",
      tag: "#온화다정다감 #날알아맞춰봐 #불편한건싫어 #나른치명",
      status: {
        charm: 5,
        romance: 4,
        // sexual: 4,
        // drive: 3,
        energy: 2,
      },
      good: "esfj",
      bad: "enfp",
    },
    isfj: {
      title: "우직한 사랑꾼 사슴",
      text:
        "안정감이 있는 연애를 추구하는 조용하고 차분한 스타일이에요. 감정을 표현하는 것에 서툴기 때문에 연인과 대화할 때 말을 예쁘게 하려고 하고 관계에 있어 항상 조심하는 편이에요. 결혼을 하기에는 이보다 더 좋은 사람이 없을 정도이고, 어른들에게도 높은 점수를 받을 수 있어요. 나의 터질 것 같은 마음을 상대방에게 잘 표현하지 못하기 때문에, 내 매력을 먼저 눈치채고 다가와 주는 사람을 만나는 게 좋아요. 그런 연인을 만났다면 부드럽고 다정하게 대해주세요!",
      tag: "#조용차분 #예쁜말고운말 #최고의배우자감",
      status: {
        charm: 3,
        romance: 4,
        // sexual: 3,
        // drive: 2,
        energy: 3,
      },
      good: "esfp",
      bad: "enfp",
    },
    istp: {
      title: "척척박사 프리한 연애 토끼",
      text:
        "객관적이고 합리적인 편이라 필요 없는 에너지는 사용하지 않는 스타일이에요. 똑똑해서 혼자서 모든 걸 잘 해내는 사람입니다. 자유로운 성향이 강해 그만큼 연인과 모든 걸 공유하는 것을 힘들어할 수 있고 때로는 혼자만의 시간을 즐기는 경우도 많아요. 그래서 연인이 서운해할 수 있지만,  직관력이 뛰어나 사소한 변화를 잘 눈치채고, 상대를 위하려는 노력을 많이 해서 상대방이 섭섭한 마음이 있다해도, 눈 녹듯 녹는 경우들이 종종 있어요. 나를 이해해 주고, 개인 시간을 존중해 주는 사람과 만나면 완벽한 연애를 할 수 있어요!",
      tag: "#똑쟁이 #자유로움의선구자 #치명적매력",
      status: {
        charm: 5,
        romance: 2,
        // sexual: 4,
        // drive: 4,
        energy: 4,
      },
      good: "estj",
      bad: "infp",
    },
    istj: {
      title: "선비 같은 사랑꾼 거북이",
      text:
        "체계적이고, 책임감이 강하며 뭐든 침착하고 집중력이 강한 현실적인 스타일이에요. 겉으로 튀는 것을 별로 안 좋아해서 사람들과 잦은 모임을 갖는 편은 아니에요. 보수적인 성향이 있어 연인 이 외의 이성에게 눈길을 돌리지 않으며 함께하는 시간이 오래될수록 연인에게 애정이 넘치는 모습을 보여줍니다. 서로 믿을 수 있는 사람이거나, 오래도록 나와 함께해 줄 수 있는 사람을 만나면 배려가 넘치는 아름다운 연애를 할 수 있어요!",
      tag: "#너바라기 #내사전에바람은없다 #시간이흐르면애교쟁이",
      status: {
        charm: 2,
        romance: 3,
        // sexual: 4,
        // drive: 3,
        energy: 3,
      },
      good: "estp",
      bad: "infp",
    },
    infp: {
      title: "생각 많은 금사빠 수달",
      text:
        "금사빠인 경우가 많아요. 상상력이 풍부하고 망상에 빠져버릴 때가 많아서 가끔은 우울한 표정을 지을 때가 많지만 그런 자신의 모습이 굉장히 멋있다고 생각하는 스타일이에요. 상상력이 풍부하다 보니 혼자서 오만가지 생각을 하다가 마음을 다 줘버리는 경우가 많아요. 한 가지의 일에 집중을 잘하는 편이라 다른 일을 할 때 연인과 연락이 안 되는 경우가 있어 연인이 오해하기도 해요. 개인적인 가치를 중요하게 생각하고 스스로의 이상향을 향해 달려나가는 성격이라 연인이 오해하거나 섭섭해하면, 무엇이 문제인지 생각이 많아지고, 스트레스를 받게 되면 마음이 확 식어버리게 돼요. 나에게 열정적이고 적극적으로 꾸준하게 애정표현을 해 주는 사람을 만나면 믿음으로 가득 찬 연애를 할 수 있어요!",
      tag: "#낭만주의 #멀티안됨 #생각많음 #우쭈쭈해줘",
      status: {
        charm: 2,
        romance: 5,
        // sexual: 3,
        // drive: 3,
        energy: 2,
      },
      good: "enfj",
      bad: "isfp",
    },
    infj: {
      title: "서툴지만  찐 사랑꾼  해마",
      text:
        "나무보다 숲을 볼 줄 아는 스타일로 인내심이 많고 통찰력과 직관력이 뛰어나요. 감수성이 강하고, 낯가림이 심해서 연애보다 짝사랑을 더 편해 하는 경우도 있어요. 혼자 생각하고 상상하고 혼자 결론지어버리는 경우도 있으니 대화를 많이 하는 게 중요해요! 대화를 통해서 이해하고 배려하고 있다고 생각하면 상대방에게 한없이 잘해주는 성격이에요. 리액션이 좋고 대화가 잘 통하는 상대라면 문제없이 행복하게 지낼 수 있답니다. 나와 마음이 잘 맞고, 대화가 통하는 사람을 만나면 재밌고 행복한 연애를 할 수 있어요!",
      tag: "#나무보단숲 #수다쟁이 #끝없는리액션 #답정너",
      status: {
        charm: 4,
        romance: 4,
        // sexual: 4,
        // drive: 4,
        energy: 5,
      },
      good: "entp",
      bad: "istp",
    },
    intp: {
      title: "똑똑한  사랑꾼 부엉이",
      text:
        "조용하고 과묵하며, 주관이 뚜렷하고 분석적인 성격의 소유자로 인생은 천상천하 유아독존이라고 생각하는 스타일이에요. 감정 기복이 심하지 않고, 감정 표현도 잘 하지 않아 상대방이 접근하기 조금 어려워한답니다. 인간관계에 있어 호불호가 뚜렷하기 때문에 '이 사람이다!'하는 사람을 만나는 데 오랜 시간이 걸려서 제풀에 지치기도 해요. 추상적이거나 논리적인 대화를 좋아하고, 부지런해서 함께 하는 무얼이든 주도적으로 이끌어주는 사람을 만난다면 엄청난 사랑꾼으로 변할 거예요!",
      tag: "#유아독존 #강같은사람 #시간이걸려도 #포기하지마",
      status: {
        charm: 3,
        romance: 2,
        // sexual: 4,
        // drive: 4,
        energy: 2,
      },
      good: "entj",
      bad: "istj",
    },
    intj: {
      title: "열정 계획파 호랑이",
      text:
        "자신에 대한 확신에 가득 차 있어 신비로운 아우라를 뿜어내는 스타일이에요. 정확하고 계산된 행동과 풍부한 지식을 소유하고 있어 하루를 철두철미하고, 감정적인 말에 잘 공감하지 못해요. 완벽주의적인 성향이 강해 썸 탈 때도 모든 행동과 사용하는 단어들, 장소, 시간 등 모든 것들이 다 계획해서 준비한 것들일 수 있어요! 그러다 보니 감성적으로 사람과 관계를 맺는 데에 오랜 시간이 걸려 연인과의 사이가 열정적으로 되기까지는 다소 시간이 필요해요. 내가 머릿속으로 계획을 세우기 전에 확 끌릴 수 있는 매력적인 연인을 만나 재밌고 티키타카가 잘 되는 사람이라면 푹 빠진 연애를 할 수 있어요! ",
      tag: "#프로계획러 #감정은어려워 #그래도사랑해",
      status: {
        charm: 3,
        romance: 4,
        // sexual: 4,
        // drive: 5,
        energy: 5,
      },
      good: "entp",
      bad: "isfj",
    },
    esfp: {
      title: "나야나! 주인공인 돌고래",
      text:
        "주위의 사람들과 상황들에 대해 관심이 많으며 수다스럽고 밝고 재밌는 에너지가 넘치는 분위기 메이커 스타일이에요. 넘치는 에너지 때문인지 사람들을 다루는 센스와 유머가 넘쳐나요. 주변에 사람이 많다 보니 연인이 질투하며 서운해할 수 있어요. 그리고 사람들 사이에서 주인공인 경우가 많아 하고 싶은 데로 데이트 코스를 짜야 할 수도 있어요. 다른 성격의 연인을 만난다면 강렬하게 끌려서 연인이 될 수 있지만 그만큼 소통 방식에서 차이가 있어 싸울 수 있으니, 주변에 사람이 많은 것을 이해해 주고 배려해 주는 사람과 만나면 최고의 짝꿍이 되어 연애할 수 있어요!  ",
      tag: "#나는야주인공 #해피바이러스 #질투는넣어둬",
      status: {
        charm: 3,
        romance: 4,
        // sexual: 4,
        // drive: 2,
        energy: 5,
      },
      good: "isfj",
      bad: "infj",
    },
    esfj: {
      title: "한없이 따뜻한 공감왕 코알라",
      text:
        "다른 사람들에게 관심이 많으며 공감을 잘하는 스타일이에요. 그래서 참을성이 많고 이타적인 행동을 많이 해요. 연인이 힘들어하거나 우울해하면 같이 우울해져요. 밝고 긍정적인 이야기를 많이 나누면 좋은 관계가 될 수 있어요. 연인과의 말다툼 시 반대 의견이나 거절당했을 때 마음의 상처를 잘 받을 수 있어요. 그렇지만 공감을 잘해줘서 든든한 존재라 크게 싸울 일이 없어요. 함께 이야기를 잘 하고 긍정적인 성격인 사람과 만나면 더없이 좋은 연애를 할 수 있을 거예요!",
      tag: "#프로공감러 #너가웃으면 #나도웃어 #긍정의미학",
      status: {
        charm: 3,
        romance: 4,
        // sexual: 3,
        // drive: 4,
        energy: 3,
      },
      good: "istp",
      bad: "infj",
    },
    estp: {
      title: "애정표현 끝판왕 햄스터",
      text:
        "순발력이 뛰어나고 선입견이 없고 자신감이 넘쳐 표현을 잘하는 애정표현의 끝판왕인 스타일이에요. 현실 감각이 뛰어나 문제해결능력이 높아 확고한 성격이지만 연애를 하게 되면 적극적으로 표현하기 때문에 살짝 당황하기도 해요. 적극적인 모습이 매력적이라 폭 빠질 수 어요. 가끔 허황된 말을  하기도 하고 지나치게 확신에 차 이해가 어렵고 갈등이 생길 수 있어요. 존중해 주고 표현을 잘 해주는 사람을 만나면 행복한 연애를 할 수 있어요!",
      tag: "#끼쟁이 #내가해결해줄게 #아수라백작 #꿈많은파랑새",
      status: {
        charm: 5,
        romance: 3,
        // sexual: 3,
        // drive: 4,
        energy: 4,
      },
      good: "istj",
      bad: "infj",
    },
    estj: {
      title: "연인에게 시선고정 미어켓",
      text:
        "매우 헌신적이며 책임감이 뛰어난 스타일이에요. 데이트를 할 때도 철저하게 계획을 세우며 절대 충동적으로 하는 일이 없어요. 완벽함을 추구하는 성향이 있어 연인을 통제하려 하기도 하기 때문에 연인이 조금 답답해할 수도 있어요. 직설적으로 바로바로 이야기하는 성격이라 뒤끝이 없는 성향이 있어요. 또한 연인을 너무 잘 챙겨주고, 연애를 최우선적으로 생각합니다. 자신이 순위에서 밀려났다는 생각이 안 들게 표현을 잘해주고 의견 표현을 확실하게 이야기해 준다면 완벽한 연애를 할 수 있어요!",
      tag: "#완벽한관계 #클라우드같은공유력 #사랑해서그래",
      status: {
        charm: 4,
        romance: 3,
        // sexual: 5,
        // drive: 5,
        energy: 5,
      },
      good: "isfp",
      bad: "enfj",
    },
    enfp: {
      title: "열정의 탱고를 추는 호랑이",
      text:
        "적극적이며 열정이 넘치는 스테미너 끝판왕 스타일이에요. 새로운 사람을 만나는 것을 좋아해 초반에 강한 대시를 할 수 있어요. 로맨티스트이기도 해서 감동을 주는 이벤트도 잘해줘요. 그만큼 인기도 많기 때문에 연인이 서운해할 만한 일이 많아요. 생각보다 눈치가 없는 척하지만 예상외로 눈치가 굉장히 빠른 편이라 상황 파악을 잘 해요. 그래서 거짓말은 잘 통하지 않으니 솔직하게 말하는 게 중요해요! 갈등이 생기더라도 대화로 잘 풀 수 있어서 관계가 나빠져도 금방 잘 회복될 수 있어요. 인기가 많아 약속이 많은 것만 이해해 준다면 재밌고 다이내믹한 연애를 할 수 있어요!",
      tag: "#스테미너 #피노키오? #말잘하면봐줌",
      status: {
        charm: 4,
        romance: 4,
        // sexual: 3,
        // drive: 5,
        energy: 5,
      },
      good: "intj",
      bad: "esfp",
    },
    enfj: {
      title: "꼬리흔드는 복슬 강아지",
      text:
        "진심으로 연인을 배려하고 걱정하며 위해주는 스타일이에요. 연인의 감정을 잘 파악하려고 하기 때문에 예민한 모습을 보이기도 해요. 서로 친밀하고 새로우면서도 가치 있는 관계가 되길 바라요. 감수성이 풍부하여 로맨틱한 분위기를 잘 이끌어내요. 애정 표현도 열정적으로 한답니다.  관심과 공감하는 대화를 하길 좋아하고 연인이 본인의 마음을 알아주길 바라요. 나와 공감이 가능하고 인정해 주는 사람을 만난다면 로맨틱한 연애를 할 수 있어요!",
      tag: "#너만본다 #사랑꾼 #감수성최고",
      status: {
        charm: 5,
        romance: 4,
        // sexual: 3,
        // drive: 2,
        energy: 3,
      },
      good: "infp",
      bad: "estp",
    },
    entp: {
      title: "느낌적인 느낌 사랑꾼 여우",
      text:
        "활동적이고 매번 새로운 것을 추구하는 스타일이에요. 이성에게 어필도 잘하고 대시도 잘 하는 성향입니다. 설득하는 능력이 높아 애프터를 잘 받기도 합니다. 자유분방하고 자존심이 강해 통제받고 억압받는 걸 누구보다 싫어해요. 지적이며 허세 없는 사람에게 강하게 끌려요. 자존심이 상하게 되면 상처를 크게 받기 때문에 이 부분에는 연인이 신경 써줘야 해요. 본능적으로 끌리는 상대에게 매력을 느끼니 직관적으로 선택할 수 있는 느낌 있는 사람을 만나게 되면 완벽한 연애를 할 수 있어요! ",
      tag: "#나한테빠지면 #못빠져나옴 #자존감왕",
      status: {
        charm: 4,
        romance: 2,
        // sexual: 4,
        // drive: 4,
        energy: 5,
      },
      good: "intj",
      bad: "estj",
    },
    entj: {
      title: "책임감강한 장군같은 치타",
      text:
        "개인의 시간을 중요하게 여기고 사적인 영역을 침범 받는 걸 싫어하는 장군 같은 스타일이에요. 성격이 뚜렷하고 독립성이 강해 상대가 무시당한다는 느낌을 받기도 하지만 이들도 그러면 안 된다는 걸 알고 있어요. 좋아하는 사람에게는 한없이 편안하고 너그러운 행동을 취해요. 낭만적인 부분과는 거리가 있기 때문에 낭만보다는 추진력과 책임감을 보시는 게 더 좋아요. 그래서 나와 비슷한 성격을 지니고 일과 커리어에 능력이 좋은 사람을 만나면 행복한 연애가 될 거예요.",
      tag: "#직설가지만 #너에게는스윗 #전략가",
      status: {
        charm: 3,
        romance: 4,
        // sexual: 3,
        // drive: 5,
        energy: 5,
      },
      good: "intp",
      bad: "esfj",
    },
  },
};
const mutations = {
  ADD_INDEX(state) {
    state.quizIndex += 1;
    state.quizTransition = false;
  },
  DONE_TRANSITION() {
    state.quizTransition = true;
  },
  SET_SCORE(state, { target, score }) {
    state.score[target] += score;
  },
  RESET(state) {
    Object.keys(state.score).forEach((v) => {
      state.score[v] = 0;
    });
    state.quizIndex = 0;
    state.quizTransition = true;
  },
};

const actions = {};

export default new Vuex.Store({
  state,
  mutations,
  actions,
});
